.message {
  width: min(600px, 90vw);
  text-align: center;
  margin-left: calc((100vw - min(600px, 90vw)) / 2);
  margin-bottom: 20px;
  margin-top: 100px;
}

.icons {
  position: relative;
  width: min(600px, 90vw);
  height: 125px;
  text-align: center;
  margin-left: calc((100vw - min(600px, 90vw)) / 2);
}

.icon {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 60px;
  color: rgb(69, 90, 100);
  transition: color 0.25s;
}
.icon:hover {
  color: rgb(0, 150, 136);
}

.iconEntrance {
  animation: iconEntrance 2s forwards;
}

.iconExit {
  animation: iconExit 2s forwards;
}

@keyframes iconEntrance {
  from {
    opacity: 0;
    transform: translate(0px, 75px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes iconExit {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.iconHoverOut {
  color: rgb(69, 90, 100);
}

.iconHoverIn {
  color: rgb(0, 150, 136);
}
