.project {
  width: min(600px, 90vw);
  text-align: center;
  margin-left: calc((100vw - min(600px, 90vw)) / 2);
  margin-top: 75px;
  margin-bottom: 75px;
  opacity: 0;
  transform: translate(0px, 100px);
  transition: opacity 2s, padding-top 2s, transform 2s;
}

.project_invisible {
  opacity: 0;
  transform: translate(0px, 100px);
}

.project_visible {
  opacity: 1;
  transform: translate(0px, 0px);
}

.image_container {
  overflow: hidden;
  height: calc(0.47 * min(600px, 90vw));
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgb(0, 0, 0, 0.25);
  transition: box-shadow 0.5s;
}
.image_container:hover {
  box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.25);
}

.image {
  cursor: pointer;
  border-radius: 20px;
  height: calc(0.47 * min(600px, 90vw));
  margin-left: 0px;
  margin-top: 0px;
  transition: opacity 1s, transform 0.5s;
}
.image:hover {
  transform: scale(1.025);
}

.image_invisible {
  opacity: 0;
}

.image_visible {
  opacity: 1;
}

.name {
  cursor: pointer;
  color: rgb(0, 150, 136);
  font-weight: bold;
  margin-top: 5px;
}
