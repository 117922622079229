.heading {
  color: rgb(0, 150, 136);
}

.download {
  cursor: pointer;
  width: min(300px, 90vw);
  text-align: center;
  margin-left: calc((100vw - min(300px, 90vw)) / 2);
  margin-bottom: 50px;
  font-size: 1.25em;
  color: rgb(96, 125, 139, 1);
  opacity: 0;
  transform: translate(0px, 100px);
  transition: opacity 2s, transform 2s, color 0.25s;
}
.download:hover {
  color: rgb(0, 150, 136);
}

.resumeBlock {
  width: min(700px, 90vw);
  text-align: center;
  margin-left: calc((100vw - min(700px, 90vw)) / 2);
  margin-bottom: 50px;
  opacity: 0;
  transform: translate(0px, 100px);
  transition: opacity 2s, transform 2s;
}

.resumeBlock_invisible {
  opacity: 0;
  transform: translate(0px, 100px);
}

.resumeBlock_visible {
  opacity: 1;
  transform: translate(0px, 0px);
}

.divider {
  margin-top: 50px;
  height: 2px;
  width: min(700px, 90vw);
  background-image: linear-gradient(
    to right,
    rgb(96, 125, 139, 0),
    rgb(96, 125, 139, 1),
    rgb(96, 125, 139, 0)
  );
}

/* https://www.w3schools.com/howto/howto_css_bullet_color.asp */
ul {
  list-style: none; /* Remove default bullets */
}
ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: rgb(0, 150, 136); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
