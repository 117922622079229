.message {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: rgb(255, 255, 255, 0.75);
  padding: 20px 50px;
  border-top: 1px solid rgb(96, 125, 139, 0.75);
  border-bottom: 1px solid rgb(96, 125, 139, 0.75);
}

.headshot {
  width: min(250px, 90vw);
  border-radius: 100%;
  margin-left: calc((99% - min(250px, 90vw)) / 2);
  box-shadow: 0px 10px 20px rgb(0, 0, 0, 0.5);
  border: 5px solid rgb(0, 150, 136);
  transition: opacity 1s, transform 1s;
}
.headshotHide {
  opacity: 0;
  transform: translate(0px, 75px);
}
.headshotShow {
  opacity: 1;
  transform: translate(0px, 50px);
}

.link {
  color: rgb(0, 150, 136);
  cursor: pointer;
  transition: color 0.5s;
}

.fadeOut {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
